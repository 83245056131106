

export default {
    //namespaced: true,
    state: {
        // 一级菜单数据
        MenuData: [],
        // 二级菜单数据
        SecondaryMenuData: {}
    },
    getters: {
    },
    mutations: {
        // 设置一级菜单数据
        setMenuData(state, data) {
            state.MenuData = data
        },
        // 修改菜单选中状态
        changeMenuSelectState(state, index) {
            state.MenuData.forEach(item => {
                item.meta.isSelected = false
            });
            state.MenuData[index].meta.isSelected = true
        },
        // 设置二级菜单数据
        setSecondaryMenuData(state, data) {
            state.SecondaryMenuData = data
        },
        // 修改二级菜单选中状态
        changeSecondaryMenuSelectState(state, index) {
            state.SecondaryMenuData.children.forEach(item => {
                item.meta.isSelected = false
            });
            state.SecondaryMenuData.children[index].meta.isSelected = true
        },

    },
    actions: {
    }
}
