import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhLocale from 'element-plus/es/locale/lang/zh-cn'

import 'element-plus/dist/index.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import router from './router'
import store from './store'

import SvgIcon from './icons/index.js'
import { vuexPersisted } from '@/utils/commons.js'


const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}

SvgIcon(app)
app.use(ElementPlus, { locale: zhLocale });
app.use(store);
app.use(router);
app.mount("#app");


// vuex持久化 防止刷新时vuex丢失数据
vuexPersisted()

const debounce = (fn, delay) => {
	let timer = null;

	return function () {
		let context = this;

		let args = arguments;

		clearTimeout(timer);

		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
};
// 以下为解决element Plus 表格bug使用的代码
// 表格内部单元格高度发生变化时会报错（ResizeObserver loop completed with undelivered notifications.）

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
};

//createApp(App).use(store).use(router).mount('#app')
