
import { getHotelshoporderDiscomplete } from '@/request/api/base'
export default {
    //namespaced: true,
    state: {
        mainDivLoading: false,
        //baseImgUrl: 'http://192.168.2.150:28888/',
        //baseImgUrl: 'https://common.zhihaozhu.com/',
        baseImgUrl: process.env.VUE_APP_BASE_IMG_URL,
        uncompleteOrderList: {
            data: [],
            total: 0
        }
    },
    getters: {
    },
    mutations: {
        // 设置加载状态
        setMainDivLoading(state, data) {
            state.mainDivLoading = data
        },
        setUncompleteOrderList(state, data) {
            state.uncompleteOrderList.data = data.data.data
            state.uncompleteOrderList.total = data.data.total
        },


    },
    actions: {
        async getUncompleteOrderNum(state) {
            let { data } = await getHotelshoporderDiscomplete()
            if (data.code == 0) {
                state.commit('setUncompleteOrderList', data)
            }
        }
    }
}
