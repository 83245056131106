<template>

  <router-view />
</template>


<script setup>

</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
}

#app,
html {
  height: 100%;
}
</style>
