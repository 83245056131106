

export default {
    //namespaced: true,
    state: {
        token: '',
        userInfo: {},
        loginStatus: ''   // 登录状态
    },
    getters: {
    },
    mutations: {
        setUserInfo(state, data) {
            state.userInfo = data
        },
        setToken(state, data) {
            state.token = data
        },
        setLoginStatus(state, data) {
            state.loginStatus = data
        },

    },
    actions: {
    }
}
