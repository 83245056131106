import store from '@/store'
import JSzip from 'jszip'
import QRcode from 'qrcodejs2'
import { saveAs } from 'file-saver'
import { ElMessageBox, ElMessage } from 'element-plus'
import { computed } from "vue";

// 深拷贝
export function deepClone(obj) {
    let objClone = Array.isArray(obj) ? [] : {};
    if (obj && typeof obj === 'object') {
        for (let key in obj) {
            if (obj[key] && typeof obj[key] === 'object') {
                objClone[key] = deepClone(obj[key]);
            } else {
                objClone[key] = obj[key]
            }
        }
    }
    return objClone;
}

// vuex持久化
export function vuexPersisted() {

    let expiresAt = localStorage.getItem('expiresAt')   // 过期时间
    let currentTime = Date.now()

    if (expiresAt - currentTime <= 0 && expiresAt != '' && expiresAt != null) {
        // 时间已经过期了
        // 无效的会话，或者会话已过期，请重新登录。
        ElMessage.error("无效的会话，或者会话已过期，请重新登录。");
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        localStorage.removeItem('expiresAt')
        return
    }

    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let token = localStorage.getItem('token')

    store.commit('setUserInfo', userInfo)
    store.commit('setToken', token)
}

// 获取菜单数据
export function getMenuData(path) {
    // 根据url上的地址来获取数据
    let routerList = JSON.parse(sessionStorage.getItem('asyncRouters'))
    routerList = routerList[0].children
    //path = '/orderManagement/mallOrders'

    let list = []
    let parentIndex = ''    // 父级菜单索引
    let childrenIndex = ''  // 二级菜单索引

    let flag = true // true代表有二级菜单 false代表没有
    routerList.forEach((item, index) => {
        if (item.path == path) {
            // 没进入children都已经匹配到路径地址，说明当前项没有二级菜单
            parentIndex = index
            list = item
            flag = false
            return
        } else if (item.children) {
            item.children.forEach((element, i) => {
                if (element.path == path) {
                    list = item
                    parentIndex = index
                    childrenIndex = i
                    return
                }
            });
        }
    });
    store.commit('changeMenuSelectState', parentIndex)
    if (flag) {
        // 只有有二级菜单才要设置
        store.commit('setSecondaryMenuData', list)
        store.commit('changeSecondaryMenuSelectState', childrenIndex)
    } else {
        store.commit('setSecondaryMenuData', list)
    }

}

export function chickPathInMenu(path) {
    //查看path是否存在二级菜单里
    //path = '/orderManagement/mallOrders'  // 测试用
    let routerList = JSON.parse(sessionStorage.getItem('asyncRouters'))
    routerList = routerList[0].children
    let flag = false
    routerList.forEach(item => {
        if (item.children) {
            item.children.forEach(element => {
                if (element.path == path) {
                    flag = true
                    return
                }
            });
        } else {
            if (item.path == path) {
                flag = true
                return
            }
        }
    });
    return flag
}

// 时间格式化
export function transformTime(timestamp = +new Date()) {
    if (timestamp) {
        var time = new Date(timestamp);
        var y = time.getFullYear(); //getFullYear方法以四位数字返回年份
        var M = time.getMonth() + 1; // getMonth方法从 Date 对象返回月份 (0 ~ 11)，返回结果需要手动加一
        var d = time.getDate(); // getDate方法从 Date 对象返回一个月中的某一天 (1 ~ 31)
        var h = time.getHours(); // getHours方法返回 Date 对象的小时 (0 ~ 23)
        var m = time.getMinutes(); // getMinutes方法返回 Date 对象的分钟 (0 ~ 59)
        var s = time.getSeconds(); // getSeconds方法返回 Date 对象的秒数 (0 ~ 59)

        if (M < 10) {
            M = '0' + M
        }
        if (d < 10) {
            d = '0' + d
        }
        if (h < 10) {
            h = '0' + h
        }
        if (m < 10) {
            m = '0' + m
        }
        if (s < 10) {
            s = '0' + s
        }
        return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    } else {
        return '';
    }
}

export function getDateHHMMSS(time) {
    if (time) {
        var h = time.getHours(); // getHours方法返回 Date 对象的小时 (0 ~ 23)
        var m = time.getMinutes(); // getMinutes方法返回 Date 对象的分钟 (0 ~ 59)
        var s = time.getSeconds(); // getSeconds方法返回 Date 对象的秒数 (0 ~ 59)

        if (h < 10) {
            h = '0' + h
        }
        if (m < 10) {
            m = '0' + m
        }
        if (s < 10) {
            s = '0' + s
        }
        return h + ':' + m + ':' + s;
    } else {
        return '';
    }
}

// 二维码下载
export function qrcodeDownload(qrcodeUrlList) {
    //let userInfo = computed(() => store.state.user.userInfo);
    //let hotel_id = userInfo.value.user_hotel_id // 酒店id

    let qrcodeDiv = document.createElement('div')
    //let baseUrl = 'https://xcx.zhihaozhu.com/wechat/xcx'
    qrcodeDiv.style.display = 'none'
    // 实例化二维码
    let qrcode = new QRcode(qrcodeDiv, {
        width: 300,//二维码宽高
        height: 300,
        colorDark: "#000000",//二维码前景色
        colorLight: "#fff",//二维码背景色
        correctLevel: QRcode.CorrectLevel.H
    })
    document.body.appendChild(qrcodeDiv)
    qrcode.clear()

    if (qrcodeUrlList.length <= 1) {
        // 下载单张二维码图片
        //let id = qrcodeUrlList[0].id    // 房间号id
        //let room_name = qrcodeUrlList[0].room_id    // 房间名
        //qrcode.makeCode(baseUrl + '?room_id=' + room_name)
        qrcode.makeCode(qrcodeUrlList[0].qrcodeUrl)
        let canvas = document.getElementsByTagName("canvas")[0]
        let data = canvas.toDataURL('image/png')
        var save_link = document.createElement('a');
        save_link.href = data;
        save_link.download = qrcodeUrlList[0].saveName;
        qrcodeDiv.appendChild(save_link)
        save_link.click()
        document.body.removeChild(qrcodeDiv)
        return
    }

    let imageDataAry = []
    // 下载多张二维码图片，打包到压缩文件一起下载
    qrcodeUrlList.forEach((item, index) => {
        //let id = qrcodeUrlList[index].id    // 房间号id
        //let room_name = qrcodeUrlList[index].room_id    // 房间名
        //qrcode.makeCode(baseUrl + '?room_id=' + qrcodeUrlList[index].room_id)
        qrcode.makeCode(item.qrcodeUrl)
        let canvas = document.getElementsByTagName("canvas")[0]
        let pic = canvas.toDataURL('image/png')

        //imageDataAry.push(pic)
        imageDataAry.push({
            qrcodeImg: pic,
            saveName: item.saveName
        })
    });
    document.body.removeChild(qrcodeDiv)
    const zip = new JSzip()
    imageDataAry.forEach((item, index) => {
        let blob = base64ToBlob(imageDataAry[index].qrcodeImg)
        zip.folder('二维码文件').file(item.saveName, blob, { binary: true })
    });

    // 生成二进制流
    zip.generateAsync({
        type: 'blob'
    }).then((content) => {
        saveAs(content, '二维码文件.zip')
    })
}

function base64ToBlob(code) {
    let parts = code.split(';base64,');
    let contnetType = parts[0].split(':')[1];
    let raw = window.atob(parts[1])
    let rawLength = raw.length;
    let uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; i++) {
        uInt8Array[i] = raw.charCodeAt(i)
    }
    return new Blob([uInt8Array], {
        type: contnetType
    })
}
// 封装MessageBox，方便使用
export function showMessageBox(title, tips, data, res, rej) {
    ElMessageBox.confirm(
        tips,
        title,
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            if (typeof res === 'function') {
                res(data)
            }
        })
        .catch(() => {
            if (typeof rej === 'function') {
                rej()
            }
        })
}

// 闭包
export function debounce() {
    let timer = null
    return function (fn, data, interval = 300) {
        clearTimeout(timer)
        timer = setTimeout(() => {
            fn(data)
        }, interval)
    }
}
