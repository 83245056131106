// 创建路由
import { createRouter, createWebHashHistory } from 'vue-router'
import { deepClone } from "@/utils/commons.js";
//import router from "@/router/index.js";

import router, { baseRoutes } from '@/router/index.js'


// 过滤出符合权限的路由
export function filterAuthRouter(List, userAuth) {
    // List：需要过滤的路由
    // userAuth：权限标识
    List = deepClone(List)
    let newList = []
    for (let i = 0; i < List.length; i++) {
        let isAuth = false
        if (List[i]["meta"] && List[i]["meta"]['auth']) {
            // 当前为需要验证权限的路由
            for (let j = 0; j < List[i].meta['roles'].length; j++) {
                if (userAuth == List[i].meta['roles'][j]) {
                    // 当前路由符合权限
                    isAuth = true
                    break
                }
            }
            if (isAuth) {
                if (List[i].children) {
                    // 存在子项
                    let children = filterAuthRouter(List[i].children, userAuth)
                    let objItem = List[i]
                    objItem.children = children
                    newList.push(objItem)
                } else {
                    // 当前项符合权限
                    newList.push(List[i])
                }
            }
        } else {
            newList.push(List[i])
        }
    }
    return newList
}


export function setAsyncRouters(routers) {
    // 深拷贝防止污染元数据
    let asyncRouters = deepClone(routers)
    sessionStorage.setItem('asyncRouters', JSON.stringify(asyncRouters))
    // 动态添加路由
    asyncRouters.push({
        path: "/:pathMatch(.*)*",
        name: '404',
        component: () => import('@/views/error/404')
    },)
    // 创建数组存放动态添加的路由名字，方便退出登录时重置路由
    const asyncRoutersNameList = []
    asyncRouters.forEach(route => {
        asyncRoutersNameList.push(route.name)
        router.addRoute(route)

    });
    sessionStorage.setItem('asyncRoutersName', JSON.stringify(asyncRoutersNameList))

}

// 重置路由
export function resetRouter() {
    const asyncRoutersNameList = JSON.parse(sessionStorage.getItem('asyncRoutersName'))
    asyncRoutersNameList.forEach(item => {
        router.removeRoute(item)
    });
}

