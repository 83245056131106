import axios from '@/request'

// 获取用户列表
export function getUserList(data) {
    return axios({
        url: '/user/getUserList',
        method: 'post',
        data: data
    })
}

export function getUserInfo() {
    return axios({
        url: '/user/getUserInfo',
        method: 'get'
    })
}


// 退出登录
export function logout() {
    return axios({
        url: '/jwt/loginout',
        method: 'post',
    })
}
