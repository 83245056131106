<template>
    <svg class="svg-icon" aria-hidden="true">
        <use :xlink:href="icon"></use>
    </svg>
</template>


<script>

import { computed } from 'vue';

export default {
    name: 'Icon',
    props: ['icon'],

    setup(props) {
        let icon = computed(() => {
            return `#icon-${props.icon}`
        })
        return {
            icon
        }
    }

}

</script>

<style scoped>
.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor; /* currentColor ：当前字体颜色是什么颜色它就是什么颜色 */
    overflow: hidden;
}
</style>