import axios from '@/request'

// 获取酒店房间列表
export function getHotelroomList(data) {
    return axios({
        url: '/hotelroom/list',
        method: 'get',
        params: data
    })
}

// 新增酒店房间信息
export function addHotelroom(data) {
    return axios({
        url: '/hotelroom/add',
        method: 'post',
        data: data
    })
}

// 修改酒店房间信息
export function editHotelroom(data) {
    return axios({
        url: '/hotelroom/edit',
        method: 'put',
        data: data
    })
}

// 通用下载（参数区分功能）
export function commonDownload(params) {
    return axios({
        baseURL: process.env.VUE_APP_BASE_COMMON_API,
        url: '/download',
        method: 'get',
        params: params,
        responseType: "blob"
    })
}

// 通用上传（参数区分功能）
export function commonUpload(params, data) {
    return axios({
        baseURL: process.env.VUE_APP_BASE_COMMON_API,
        url: '/upload',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        method: 'post',
        params: params,
        data: data
    })
}

// 客房模板确认导入
export function hotelroomsImportConfirm(data) {
    return axios({
        url: '/hotelroom/read',
        method: 'post',
        data: data
    })
}

// 下载房间二维码
export function downloadQRcode() {
    return axios({
        url: '/hotelroom/downloadqrcode',
        method: 'get',
    })
}

// 获取员工信息数据列表
export function getHotelemployeeList(data) {
    return axios({
        url: '/hotelemployee/list',
        method: 'get',
        params: data
    })
}

// 新增员工信息
export function addHotelemployee(data) {
    return axios({
        url: '/hotelemployee/add',
        method: 'post',
        data: data
    })
}

// 修改员工信息
export function editHotelemployee(data) {
    return axios({
        url: '/hotelemployee/edit',
        method: 'put',
        data: data
    })
}

// 删除员工信息
export function deletesHotelemployee(data) {
    return axios({
        url: '/hotelemployee/deletes',
        method: 'delete',
        data: data
    })
}

// 员工模板确认导入
export function hotelemployeeImportConfirm(data) {
    return axios({
        url: '/hotelemployee/read',
        method: 'post',
        data: data
    })
}

// 获取Banner分类列表
export function getHotelbannerCate() {
    return axios({
        url: 'hotelbanner/catelist',
        method: 'get',
    })
}

// 获取Banner数据列表
export function getHotelbannerList(data) {
    return axios({
        url: 'hotelbanner/list',
        method: 'get',
        params: data
    })
}

// 新增Banner信息
export function addHotelbanner(data) {
    return axios({
        url: 'hotelbanner/add',
        method: 'post',
        data: data
    })
}


// 修改Banner信息
export function editHotelbanner(data) {
    return axios({
        url: 'hotelbanner/edit',
        method: 'put',
        data: data
    })
}


// 删除Banner信息
export function deletesHotelbanner(data) {
    return axios({
        url: 'hotelbanner/deletes',
        method: 'delete',
        data: data
    })
}

// 获取酒店商城商品分类
export function getHotelShopGoodCate(data) {
    return axios({
        url: 'hotelshopgoodcate/list',
        method: 'get',
        params: data
    })
}

// 获取酒店商城商品列表
export function getHotelShopGoodlist(data) {
    return axios({
        url: 'hotelshopgood/list',
        method: 'get',
        params: data
    })
}

// 删除酒店商城商品分类
export function deletesHotelShopGoodCate(data) {
    return axios({
        url: 'hotelshopgoodcate/deletes',
        method: 'delete',
        data: data
    })
}

// 新增商品分类
export function addHotelShopGoodCate(data) {
    return axios({
        url: 'hotelshopgoodcate/add',
        method: 'post',
        data: data
    })
}

// 修改商品分类
export function editHotelShopGoodCate(data) {
    return axios({
        url: 'hotelshopgoodcate/edit',
        method: 'put',
        data: data
    })
}

// 设置服务时间
export function setHotelshopcatectroTime(data) {
    return axios({
        url: 'hotelshopcatectrotime/setctrotime',
        method: 'post',
        data: data
    })
}

// 新增商品信息
export function addHotelshopgood(data) {
    return axios({
        url: 'hotelshopgood/add',
        method: 'post',
        data: data
    })
}


// 修改商品信息
export function editHotelshopgood(data) {
    return axios({
        url: '/hotelshopgood/edit',
        method: 'put',
        data: data
    })
}

// 删除商品信息
export function deletesHotelshopgood(data) {
    return axios({
        url: '/hotelshopgood/deletes',
        method: 'delete',
        data: data
    })
}

// 获取优惠券可用的商城列表
export function getHotelshopcateList() {
    return axios({
        url: '/hotelshopcate/list',
        method: 'get',
    })
}

// 获取优惠券分类列表
export function getHotelcouponscateList(data) {
    return axios({
        url: 'hotelcouponscate/list',
        method: 'get',
        params: data
    })
}

// 获取优惠券数据列表
export function getHotelcouponsList(data) {
    return axios({
        url: '/hotelcoupons/list',
        method: 'get',
        params: data
    })
}

// 获取优惠券数据列表数量
export function getHotelcouponsNum() {
    return axios({
        url: '/hotelcouponscate/num',
        method: 'get'
    })
}

// 获取服务时间列表
export function getServiceTimeList(data) {
    return axios({
        url: 'hotelshopcatectrotime/find',
        method: 'get',
        params: data
    })
}

// 新增优惠券信息
export function addHotelcoupons(data) {
    return axios({
        url: '/hotelcoupons/add',
        method: 'post',
        data: data
    })
}

// 修改优惠券信息
export function editHotelcoupons(data) {
    return axios({
        url: '/hotelcoupons/edit',
        method: 'put',
        data: data
    })
}

// 删除优惠券信息
export function deletesHotelcoupons(data) {
    return axios({
        url: '/hotelcoupons/deletes',
        method: 'delete',
        data: data
    })
}

// 获取酒店订单列表
export function getHotelshoporderList(data) {
    return axios({
        url: 'hotelshoporder/list',
        method: 'get',
        params: data
    })
}

// 获取酒店评论列表（包括酒店评价和订单评价）
export function getHotelreplayList(data) {
    return axios({
        url: 'hotelreplay/list',
        method: 'get',
        params: data
    })
}

// 指派员工
export function editHotelshoporder(data) {
    return axios({
        url: 'hotelshoporder/edit',
        method: 'put',
        data: data
    })
}

// 修改订单状态
export function editHotelshoporderStatus(data) {
    return axios({
        url: 'hotelshoporder/status',
        method: 'post',
        data: data
    })
}

// 取消订单并退款
export function refundsHotelshoporder(data) {
    return axios({
        url: 'sdk/pay/refundsorder',
        method: 'get',
        params: data
    })
}

// 修改订单已送达
export function editHotelshoporderServed(data) {
    return axios({
        url: 'hotelshoporder/served',
        method: 'get',
        params: data
    })
}


// 获取未完成订单数量
export function getHotelshoporderDiscomplete() {
    return axios({
        url: 'hotelshoporder/discomplete',
        method: 'get',
    })
}


// 获取酒店服务分类列表
export function getHotelserverCate(data) {
    return axios({
        url: 'hotelservercate/list',
        method: 'get',
        params: data
    })
}


// 获取酒店服务列表
export function getHotelserverList(data) {
    return axios({
        url: 'hotelserver/list',
        method: 'get',
        params: data
    })
}


// 新增服务指南
export function addHotelserver(data) {
    return axios({
        url: 'hotelserver/add',
        method: 'post',
        data: data
    })
}

// 删除服务信息
export function deletesHotelserver(data) {
    return axios({
        url: 'hotelserver/deletes',
        method: 'delete',
        data: data
    })
}

// 修改服务信息
export function editHotelserver(data) {
    return axios({
        url: 'hotelserver/edit',
        method: 'put',
        data: data
    })
}

// 查询发票订单列表
export function getUserinvoiceorderList(data) {
    return axios({
        url: 'userinvoiceorder/list',
        method: 'get',
        params: data
    })
}

// 完成发票订单
export function editUserinvoiceorderStatus(data) {
    return axios({
        url: 'userinvoiceorder/status',
        method: 'get',
        params: data
    })
}

// 获取申请提现订单的列表
export function getHotelpayoutorderList(data) {
    return axios({
        url: 'hotelpayoutorder/list',
        method: 'get',
        params: data
    })
}

// 提交申请提现订单
export function addHotelpayoutorder(data) {
    return axios({
        url: 'hotelpayoutorder/add',
        method: 'post',
        data: data
    })
}

// 修改提现订单状态（用来取消订单）
export function editHotelpayoutorder(data) {
    return axios({
        url: 'hotelpayoutorder/status',
        method: 'get',
        params: data
    })
}

// 获取当前账户金额相关的信息
export function getAdminCoinInfo() {
    return axios({
        url: 'admincoin/coin',
        method: 'get'
    })
}





// 下面是总后台相关

// 获取酒店列表
export function getHotelList(data) {
    return axios({
        url: 'super/hotel/list',
        method: 'get',
        params: data
    })
}

// 新增酒店
export function addHotel(data) {
    return axios({
        url: 'super/hotel/add',
        method: 'post',
        data: data
    })
}

// 修改酒店信息
export function editHotel(data) {
    return axios({
        url: 'super/hotel/edit',
        method: 'put',
        data: data
    })
}

// 删除酒店
export function deletesHotel(data) {
    return axios({
        url: 'super/hotel/deletes',
        method: 'delete',
        data: data
    })
}

// 获取管理员列表
export function getAdminUserList(data) {
    return axios({
        url: 'super/user/getuserlist',
        method: 'get',
        params: data
    })
}

// 新增管理员
export function addAdminUser(data) {
    return axios({
        url: 'super/user/adminregister',
        method: 'post',
        data: data
    })
}

// 修改管理员
export function editAdminUser(data) {
    return axios({
        url: 'super/user/setuserinfo',
        method: 'post',
        data: data
    })
}

// 管理员修改密码
export function resetAdminPassword(data) {
    return axios({
        url: 'super/user/resetpassword',
        method: 'post',
        data: data
    })
}

// 获取申请提现订单列表
export function getHotelsuperpayoutorderList(data) {
    return axios({
        url: 'super/hotelsuperpayoutorder/list',
        method: 'get',
        params: data
    })
}

// 修改提现订单状态
export function editHotelsuperpayoutorderStatus(data) {
    return axios({
        url: 'super/hotelsuperpayoutorder/status',
        method: 'get',
        params: data
    })
}

// 获取酒店数据（总后台首页）
export function getHotelIndexData() {
    return axios({
        url: 'super/index/data',
        method: 'get',
    })
}

